import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="404" description="Seite nicht gefunden" />
      <Container>
        <Row>
          <Col className="text-center my-5">
            <h1>404 – Seite nicht gefunden</h1>
            <Link to="/">Zurück zur Startseite</Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
